<template>
    <div class="base-container">
        <el-card class="box-card">
            <div class="item" v-loading="loading">
                <el-button class="back-btn"
                           size="mini"
                           icon="el-icon-back"
                           @click="onBack()"
                >返回
                </el-button>
                <el-carousel v-if="dataList.length>0" ref="carouselRef" :interval="5000" :initial-index="initialIndex"
                             :autoplay="false"
                             :loop="false" arrow="never"
                             indicator-position="none">
                    <el-carousel-item v-for="(item, index) in dataList" :key="index">
                        <div class="giveMark flex">
                            <!-- left -->
                            <div class="mark_left">
                                <i class="arrow el-icon-arrow-left" @click="onPrev()"></i>
                                <i class="arrow el-icon-arrow-right" @click="onNext()"></i>
                                <div class="imgBox img_big">
                                    <el-image
                                            fit="contain"
                                            :src="$store.getters.getFilePath+item.images"
                                            :preview-src-list="[$store.getters.getFilePath+item.images]"
                                    >
                                    </el-image>
                                </div>
                                <div class="work-content">
                                    <div>作品编号：{{item.number}}</div>
                                    <!--<div>精度：{{item.precision}}</div>-->
                                </div>
                            </div>
                            <!-- right -->
                            <div class="mark_right">
                                <!-- 科目名称 -->
                                <div class="generalReview aa">
                                    <div class="flex">
                                        <div class="item-box">科目名称: {{$store.state.subjectObj.subject_name}}</div>
                                        <div class="item-box">作品总数: {{total}}</div>
                                    </div>
                                </div>

                                <div style="text-align: center;margin-top: 40px">
                                    <el-button type="primary" @click="setAbnormal(item.id_card, 1)">提交异常</el-button>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <el-empty v-else class="empty-area" description="暂无数据"></el-empty>
            </div>
        </el-card>
        <!--标记异常弹框-->
        <abnormal-box
                :dialogVisibleFu="dialogVisibleFu"
                :dataObjFu="dataObjFu"
                :typeFu="true"
                @closeAbnormalBox="closeAbnormalBox"
        ></abnormal-box>
    </div>
</template>

<script>
    import {getStudentScoreList, getStudentScoreList2} from "../../api/score";
    import AbnormalBox from "../../components/AbnormalBox";

    export default {
        name: "giveMark",
        components: {AbnormalBox},
        data() {
            return {
                query: {
                    start: 1,
                    size: 1,
                    order: 1,
                    scores: 10,
                },
                total: 0,
                dataList: [],
                initialIndex: 0,
                loading: false,

                dialogVisibleFu: false,// 控制标记异常弹框显示与隐藏
                dataObjFu: {
                    message: '',
                    id_card: null,
                    status: null// 1-标为异常 2-取消异常
                },
                isDisKeyDown: false,// true-禁用keyDown
            };
        },
        created() {
            let query = this.$route.query;
            let queryKey = this.$route.query;
            if (queryKey && Object.keys(queryKey).length > 0) {
                this.query.start = Number(query.start);
            }
            this.getList();
            this.keyDown();
        },
        methods: {
            // 监听键盘-左右键翻页
            keyDown() {
                document.onkeydown = (e) => {
                    //事件对象兼容
                    let e1 = e || event || window.event || arguments.callee.caller.arguments[0];
                    //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
                    //左
                    if (e1 && e1.keyCode == 37 && !this.isDisKeyDown) {
                        // 按下左箭头
                        this.onPrev();
                    } else if (e1 && e1.keyCode == 39 && !this.isDisKeyDown) {
                        // 按下右箭头
                        this.onNext();
                    }
                }
            },
            // 返回
            onBack() {
                let size = 0,
                    start = 0,
                    start_key = '',
                    size_key = '',
                    path = '';
                start_key = 'normal_start';
                size_key = 'normal_size';
                path = '/normal/list';
                size = window.localStorage.getItem(size_key);
                start = this.query.start % size == 0 ? this.query.start / size : Math.ceil(this.query.start / size);
                window.localStorage.setItem(start_key, start.toString());
                return this.$router.replace(path);
            },
            onPrev() {
                if (this.query.start > 1) {
                    this.query.start--;
                    this.getList();
                } else {
                    this.$message.info('到顶了');
                }
            },
            onNext() {
                if (this.query.start < this.total) {
                    this.query.start++;
                    this.getList();
                } else {
                    this.$message.info('到底了');
                }
            },
            toNextHandle() {
                if (this.query.start < this.total) {
                    this.getList();
                } else {
                    this.query.start = 1;
                    this.getList();
                }
            },
            // 子组件触发，关闭标记异常弹框
            closeAbnormalBox(val, id_card) {
                this.dialogVisibleFu = val;
                this.isDisKeyDown = false;
                if (id_card) {
                    this.toNextHandle();
                }
            },
            // 标记异常
            setAbnormal(id_card, type) {
                this.dataObjFu = {
                    message: '',
                    id_card: id_card,
                    status: type
                }
                this.dialogVisibleFu = true;
                this.isDisKeyDown = true;
            },
            getList() {
                if (this.$store.state.teacherType == 1) {
                    // 阅卷老师
                    this.requestHandle(getStudentScoreList)
                } else if (this.$store.state.teacherType == 3) {
                    // 第二轮
                    this.requestHandle(getStudentScoreList2)
                }
            },
            requestHandle(api) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                api(this.query)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            this.dataList = [];
                            this.total = 0;
                            this.loading = false;
                            return this.$message.error(res.data.message)
                        }
                        this.loading = false;
                        this.dataList = res.data.data.list;
                        this.total = res.data.data.sum;
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    })
            }
        },
        destroyed() {
            // 删除事件处理器
            document.onkeydown = null;
        },
    };
</script>

<style scoped lang="scss">
    @import "../../assets/css/giveMark";

</style>
